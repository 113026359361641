import { createHttpEndpoint } from '../../utils'

/**
 * Get crm customer id
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/crm-service-api/definition#tag/Customer/operation/get-customer-id-v1}
 */
export const getCustomerId = createHttpEndpoint<{ crmId: string }>({
  method: 'GET',
  operationId: 'getUserCrmId',
  path: '/crm/customers/v1/data/id',
})
