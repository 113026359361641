import type { RichText } from '@backmarket/http-api/src/api-specs-content/models/rich-text'
import type { Block, Inline, Text } from '@contentful/rich-text-types'

const MS_IN_DAY = 1000 * 3600 * 24

function formatDifference(differenceInDays: number, locale: string) {
  const absoluteDays = Math.abs(differenceInDays)
  const formatter = new Intl.RelativeTimeFormat(locale, {
    numeric: 'auto',
    style: 'long',
  })

  if (absoluteDays < 30)
    return formatter.format(Math.trunc(differenceInDays), 'day')
  if (absoluteDays < 90)
    return formatter.format(Math.trunc(differenceInDays / 7), 'week')
  if (absoluteDays < 365)
    return formatter.format(Math.trunc(differenceInDays / 30), 'month')

  return formatter.format(Math.trunc(differenceInDays / 365), 'year')
}

function parseTargetDatePattern(chunk: string, locale: string) {
  const targetPattern = /\[(target_date:)(\d{4}-\d{2}-\d{2})\]/g

  let textChunk = chunk
  for (const targetMatch of textChunk.matchAll(targetPattern)) {
    const matchChunk = targetMatch[0]
    const dateChunk = targetMatch[2]

    const difference = new Date(dateChunk).getTime() - new Date().getTime()

    const differenceInDays = Math.floor(difference / MS_IN_DAY)
    const formattedDifference = formatDifference(differenceInDays, locale)

    textChunk = textChunk.replaceAll(matchChunk, formattedDifference)
  }

  return textChunk
}

function parseTodayPattern(chunk: string, locale: string) {
  const todayPattern = /(\[today\])/g

  let textChunk = chunk
  for (const todayMatch of textChunk.matchAll(todayPattern)) {
    const matchChunk = todayMatch[0]
    const todayDate = new Date().toLocaleDateString(locale, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })

    textChunk = textChunk.replace(matchChunk, todayDate)
  }

  return textChunk
}

export function parseText(textChunk: string, locale: string) {
  let parsedChunk = textChunk
  parsedChunk = parseTodayPattern(parsedChunk, locale)

  return parseTargetDatePattern(parsedChunk, locale)
}

function parseContent(
  content: (Block | Inline | Text)[],
  locale: string,
): (Block | Inline | Text)[] {
  return content.map((contentItem) => {
    if (contentItem.nodeType === 'text') {
      return { ...contentItem, value: parseText(contentItem.value, locale) }
    }

    const parsedContent = parseContent(contentItem.content, locale)

    return { ...contentItem, content: parsedContent } as Block | Inline
  })
}

export function parseRichText(richText: RichText, locale: string): RichText {
  const parsedRichText = richText

  parsedRichText.content = parsedRichText.content.map((contentItem) => {
    if ('content' in contentItem) {
      return {
        ...contentItem,
        content: parseContent(contentItem.content, locale),
      }
    }

    return contentItem
  })

  return parsedRichText
}
