import {
  $fetch,
  type FetchOptions as OfetchOptions,
  type FetchRequest as OfetchPath,
} from 'ofetch'

import { isBrowser } from '@backmarket/utils/env/isBrowser'
import { camelizeKeys } from '@backmarket/utils/object/camelizeKeys'
import { decamelizeKeys } from '@backmarket/utils/object/decamelizeKeys'
import { mapValues } from '@backmarket/utils/object/mapValues'

import { HttpApiError } from './HttpApiError'
import {
  type DefaultHttpRequestBody,
  type HttpContext,
  type HttpEndpoint,
  type HttpEndpointSettings,
  HttpEvent,
  type HttpRequestOptions,
  type HttpUnknownResponsePayload,
} from './types'

const PATH_VARIABLE_REGEXP = /:[A-Za-z\d]+\??/g

/**
 * Replace all endpoint path variables by their values, passed when a request is
 * being made.
 *
 * Ex:
 * - endpoint is declared with `{ path: '/bm/product/v2/:id' }`
 * - request is called with `{ path: { id: '1234' }}`
 * => returned path is '/bm/product/v2/1234'
 *
 * @param endpointSettings Endpoints settings
 * @param requestOptions Options passed when an endpoint was called
 * @returns The path to pass to {@link https://github.com/unjs/ofetch ofetch}
 * @throws An error if an endpoint expected a variable, but no value is passed
 * when the endpoint is called
 */
function getOfetchPath<T, B>(
  endpointSettings: HttpEndpointSettings,
  requestOptions: HttpRequestOptions<T, B>,
): OfetchPath {
  const rawPath = requestOptions.path || endpointSettings.path

  const pathVariableMatches = rawPath.match(PATH_VARIABLE_REGEXP) || []
  const pathVariables = pathVariableMatches.map((match) => ({
    match,
    name: match.replace(/^:/, ''),
  }))

  return pathVariables.reduce((resolvedPath, { name, match }) => {
    const value = requestOptions.pathParams?.[name]

    if (value === undefined) {
      throw new Error(
        `Missing path variable "${name}" when calling ${endpointSettings.operationId} endpoint (path: "${rawPath}")`,
      )
    }

    return resolvedPath.replace(match, String(value) || '')
  }, rawPath)
}

/**
 * @param endpointSettings Endpoints settings
 * @param requestOptions Options passed when an endpoint was called
 * @returns The options to pass to {@link https://github.com/unjs/ofetch ofetch}
 */
function getOfetchOptions<T, B>(
  endpointSettings: HttpEndpointSettings,
  requestOptions: HttpRequestOptions<T, B>,
): OfetchOptions<'json'> {
  const baseURL =
    requestOptions.baseURL ||
    endpointSettings.baseURL ||
    requestOptions.defaultBaseURL ||
    null

  let body = requestOptions.body || null

  if (
    endpointSettings.transformRequestToSnakeCase &&
    body !== null &&
    typeof body !== 'string' &&
    !(body instanceof Blob) &&
    !(body instanceof FormData) &&
    !(body instanceof ArrayBuffer) &&
    !(body instanceof ReadableStream) &&
    !(body instanceof URLSearchParams) &&
    !ArrayBuffer.isView(body)
  ) {
    body = decamelizeKeys(body) as NonNullable<B>
  }

  const headers = mapValues(
    {
      ...endpointSettings.headers,
      ...requestOptions.headers,
    },
    (value) => String(value),
  )

  const mergedQuery =
    endpointSettings.defaultQueryParams || requestOptions.queryParams
      ? {
          ...endpointSettings.defaultQueryParams,
          ...requestOptions.queryParams,
        }
      : null
  const query =
    endpointSettings.transformRequestToSnakeCase && mergedQuery
      ? decamelizeKeys(mergedQuery)
      : mergedQuery

  // Timeout priority: request options >> default endpoint settings >> default timeout
  const defaultTimeout = isBrowser() ? 20_000 : 5_000
  const timeout =
    requestOptions.timeout ?? endpointSettings.timeout ?? defaultTimeout

  return {
    // Allow cross-origin credentials, as it is required in some scenarios (ex:
    // `POST /bm/merchants/login` from dev.backmarket.* domain must be able to
    // set some cookies)
    credentials: endpointSettings.credentials || 'include',

    timeout,
    headers,
    method: endpointSettings.method,
    signal: requestOptions.signal,
    ...(baseURL ? { baseURL } : {}),
    ...(body ? { body } : {}),
    ...(query ? { query } : {}),
  }
}

/**
 * Declare a {@link HttpEndpoint HTTP endpoint}.
 *
 * @typeParam T The type of the endpoint's response body
 * @param endpointSettings Endpoint settings, see {@link HttpEndpointSettings}
 */
export function createHttpEndpoint<
  T extends HttpUnknownResponsePayload,
  B = DefaultHttpRequestBody,
>(endpointSettings: HttpEndpointSettings): HttpEndpoint<T, B> {
  async function endpoint(requestOptions: HttpRequestOptions<T, B> = {}) {
    const context: HttpContext<T, B> = { endpointSettings, requestOptions }
    requestOptions.onEvent?.(HttpEvent.Attempt, { ...context })

    try {
      const ofetchPath = getOfetchPath<T, B>(endpointSettings, requestOptions)
      const ofetchOptions = getOfetchOptions<T, B>(
        endpointSettings,
        requestOptions,
      )

      const rawResponse = await $fetch.raw<T>(ofetchPath, ofetchOptions)

      // See https://github.com/unjs/ofetch#-access-to-raw-response
      // as long as the `ignoreResponseError` config is false, `_data` is always defined
      // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-non-null-assertion
      const rawData = rawResponse._data!

      context.data = endpointSettings.transformResponseToCamelCase
        ? (camelizeKeys(rawData) as T)
        : rawData

      requestOptions.onEvent?.(HttpEvent.Success, {
        ...context,
        response: rawResponse,
      })

      return context.data
    } catch (error) {
      context.error = HttpApiError.fromAnyError(error as Error)

      requestOptions.onEvent?.(HttpEvent.Fail, { ...context })
      throw context.error
    }
  }

  return Object.assign(endpoint, { settings: endpointSettings })
}
