export const PROCESS_KEY = 'i18n'
export const NUXT_STATE_KEY = 'i18n'
export const CLIENT_GLOBAL_KEY = '__BackmarketTranslations'

/**
 * Periodic fetch config
 */
const MINUTES = 60 * 1000
export const FETCH_INTERVAL = 15 * MINUTES
export const FETCH_RETRY_ATTEMPT = 5

/**
 * Cookies
 */
export const COOKIE_LANGUAGE = 'BM_lang'
export const COOKIE_SHOW_DEFAULT_TRANSLATIONS = 'BM_USE_DEFAULT_TRANSLATIONS'

/**
 * Request Headers
 */
export const HEADER_NGINX_LOCALE = 'x-bm-locale'
export const HEADER_CFWORKER_LOCALE = 'bm-locale'
