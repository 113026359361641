import { defineNuxtPlugin, useHead } from '#imports'

import { isTestEnv } from '@backmarket/utils/env/isTestEnv'

import { useI18n } from './composables/useI18n'
import { CLIENT_GLOBAL_KEY, NUXT_STATE_KEY, PROCESS_KEY } from './constants'
import type { I18n } from './types'
import { getRequestLocale } from './utils/getRequestLocale'

export type Injections = {
  i18n: I18n
}

export default defineNuxtPlugin<Injections>(function i18nPlugin(nuxt) {
  if (nuxt.ssrContext) {
    const locale = getRequestLocale(nuxt.ssrContext.event)

    if (!globalThis[PROCESS_KEY]) {
      throw new Error(
        '[nuxt-module-i18n] Cannot retrieve translations from the main process',
      )
    }

    const translations = globalThis[PROCESS_KEY].translationsByLocale[locale]

    if (translations === undefined) {
      throw new Error(
        '[nuxt-module-i18n] Cannot retrieve translations for current locale',
      )
    }

    // eslint-disable-next-line no-param-reassign
    nuxt.payload.state[NUXT_STATE_KEY] = {
      locale,
    }

    useHead({
      script: [
        {
          // after main entry file (seems to be 100)
          tagPriority: 110,
          key: 'translations',
          innerHTML: `window.${CLIENT_GLOBAL_KEY} = ${JSON.stringify({ translations })};`,
        },
      ],
    })
  }

  if (!isTestEnv() && !nuxt.payload.state[NUXT_STATE_KEY].locale) {
    throw new Error('[nuxt-module-i18n] Cannot retrieve locale in useState')
  }

  return {
    provide: {
      i18n: useI18n(),
    },
  }
})
