import { safelyDefineStore } from './safelyDefineStore'

type State = {
  /**
   * The CSRF token used to prevent cross site request forgery attacks when
   * communicating with Badoom.
   */
  csrf?: string
}

export const useHttpStore = safelyDefineStore('http', {
  state(): State {
    return {
      csrf: undefined,
    }
  },
  actions: {
    $reset() {
      this.csrf = undefined
    },
  },
})
