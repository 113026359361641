import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

import { ROUTES as AUTH_ROUTES } from '../auth/route-names'

import { CUSTOMER_CARE } from './route-names'

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const {
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_DIAGNOSIS,
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESOLUTION_FLOW,
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESOLUTION_CONTACT_CONFIRMATION,
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESOLUTION_RETURN_CONFIRMATION,
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESOLUTION_STRAIGHT_REFUND_CONFIRMATION,
  } = useRuntimeConfig().public

  const isDiagnosisFSMEnabled = (
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_DIAGNOSIS as string
  )?.includes(market.countryCode)

  const isResolutionFlowFSMEnabled = (
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESOLUTION_FLOW as string
  )?.includes(market.countryCode)

  const isResolutionContactConfirmationFSMEnabled = (
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESOLUTION_CONTACT_CONFIRMATION as string
  )?.includes(market.countryCode)

  const isResolutionReturnConfirmationFSMEnabled = (
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESOLUTION_RETURN_CONFIRMATION as string
  )?.includes(market.countryCode)

  const isResolutionStraightRefundConfirmationFSMEnabled = (
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESOLUTION_STRAIGHT_REFUND_CONFIRMATION as string
  )?.includes(market.countryCode)

  return [
    {
      path: 'dashboard/diagnosis/new/:orderlineId',
      name: CUSTOMER_CARE.DIAGNOSIS.SELF,
      component: () => import('./diagnosis/Diagnosis.vue'),
      meta: {
        auth: {
          required: true,
          // TODO: [FRONT-1199] remove this after global config change
          redirection: { name: AUTH_ROUTES.AUTH.LOGIN },
        },
        layout: 'minimal',
        disableClientSideNavigation: () => !isDiagnosisFSMEnabled,
      },
    },
    {
      path: 'dashboard/resolution/new/:orderlineId/:customerIssues',
      name: CUSTOMER_CARE.RESOLUTION.SELF,
      component: () => import('./resolution/ResolutionFlow.vue'),
      meta: {
        auth: {
          required: true,
          // TODO: [FRONT-1199] remove this after global config change
          redirection: { name: AUTH_ROUTES.AUTH.LOGIN },
        },
        layout: 'minimal',
        disableClientSideNavigation: () => !isResolutionFlowFSMEnabled,
      },
    },
    {
      path: 'dashboard/resolution/confirmation/:resolutionId?',
      name: CUSTOMER_CARE.RESOLUTION.RESOLUTION_CONTACT_CONFIRMATION,
      component: () =>
        import(
          './resolution/confirmationPages/contact/ContactConfirmationPage.vue'
        ),
      meta: {
        auth: {
          required: true,
        },
        disableClientSideNavigation: () =>
          !isResolutionContactConfirmationFSMEnabled,
      },
    },
    {
      path: 'dashboard/resolution/return/confirmation/:resolutionId',
      name: CUSTOMER_CARE.RESOLUTION.RESOLUTION_RETURN_CONFIRMATION,
      component: () =>
        import(
          './resolution/confirmationPages/return/ReturnConfirmationPage.vue'
        ),
      meta: {
        auth: {
          required: true,
        },
        layout: 'minimal',
        disableClientSideNavigation: () =>
          !isResolutionReturnConfirmationFSMEnabled,
      },
    },
    {
      path: 'dashboard/resolution/straight-refund/confirmation',
      name: CUSTOMER_CARE.RESOLUTION.RESOLUTION_STRAIGHT_REFUND_CONFIRMATION,
      component: () =>
        import(
          './resolution/confirmationPages/straightRefund/StraightRefundConfirmationPage.vue'
        ),
      meta: {
        auth: {
          required: true,
        },
        disableClientSideNavigation: () =>
          !isResolutionStraightRefundConfirmationFSMEnabled,
      },
    },
  ]
}
