import { type Currency } from '@backmarket/http-api'
import { getCustomerId } from '@backmarket/http-api/src/api-specs-crm-service/crm-service/customer'
import { useAuthStore } from '@backmarket/nuxt-layer-oauth/useAuthStore'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

export async function updateUserContext() {
  const { userId } = useAuthStore()

  if (!userId) {
    return
  }

  try {
    const userStore = useUserStore()
    const { user } = userStore

    const crmData = user.clientId ? await $httpFetch(getCustomerId) : undefined
    const crmId = crmData ? crmData.crmId : ''

    const tracking = useTracking()

    tracking.setUserContext({
      userId,
      isStaff: user.isStaff,
      clientId: user.clientId,
      emailHashed: user.emailHashed || '',
      merchantId: user.merchantId,
      lastOrderDate: String(user.lastOrderDate),
      numberOfOrders: user.numberOfOrders,
      lifetimeRevenue: {
        currency: <Currency>user.lifetimeRevenue.currency || '',
        amount: String(user.lifetimeRevenue.amount),
      },
      crmId,
    })
  } catch (err) {
    const logger = useLogger()

    const error = err as Error
    logger.error(error.message, { error })
  }
}
