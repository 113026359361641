<template>
  <slot />
</template>

<script setup lang="ts">
import { onErrorCaptured } from 'vue'

import type { Owner } from './types'

const props = defineProps<{
  owners: Owner[]
}>()

function hasOwners(cause: unknown): cause is { owners: Owner[] } {
  if (!cause) return false
  if (!Object.hasOwn(cause, 'owners')) return false

  return true
}

onErrorCaptured((error, instance) => {
  // eslint-disable-next-line no-underscore-dangle
  const componentName = instance?.$options?.__name || 'none'
  const owners = hasOwners(error.cause) ? error.cause.owners : props.owners

  try {
    if (error instanceof Error)
      // eslint-disable-next-line no-param-reassign
      error.cause = error.cause
        ? { ...error.cause, owners, componentName }
        : { owners, componentName }
  } catch {
    // Do nothing,
  }

  // Propagate the error in the order of bottom to top.
  // This is similar to the bubbling mechanism of native DOM events
  return true
})
</script>
