import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'

import type { EventData } from '../../types'
import { formatPrice } from '../../utils/formatPrice'

export const productModel = ({
  brand,
  category,
  chillpackDuration,
  color = '',
  currency,
  dealType,
  id,
  insurancePrice,
  insurancePaymentType,
  insuranceTitle,
  isSwapEnabled,
  list,
  listingId,
  merchantId,
  model,
  name,
  position = 1,
  price,
  provider,
  // number of products selected by the user (funnel)
  quantity,
  isOutOfStock,
  shipper,
  shippingDelay,
  shippingOptions = [],
  shippingPrice,
  source,
  splitPayment,
  trustpackDuration,
  uuid,
  variant,
  warrantyDuration,
  mobilePlanOfferSelected,
}: EventData) => {
  return removeEmptyValuesInObject({
    list,
    position,
    brand,
    category,
    name,
    price: formatPrice({ amount: price, currency }),
    id: id && String(id),
    quantity,
    // The next keys names are defined by Google Analytics
    variant,
    dimension14: color,
    dimension16: trustpackDuration,
    dimension17: shippingDelay,
    dimension20: merchantId && String(merchantId),
    dimension24: model,
    dimension31: isSwapEnabled,
    dimension39: dealType,
    dimension46: shipper,
    dimension47: source,
    dimension48: listingId && String(listingId),
    dimension49: chillpackDuration,
    dimension50: warrantyDuration,
    dimension60: formatPrice({ amount: shippingPrice, currency }),
    dimension63: splitPayment,
    dimension68: insuranceTitle,
    dimension69: insurancePrice,
    dimension81: provider,
    dimension82: Array.isArray(shippingOptions)
      ? shippingOptions.join('|')
      : '',
    dimension83: isOutOfStock,
    dimension89: uuid,
    dimension90: mobilePlanOfferSelected,
    dimension91: insurancePaymentType,
  })
}

export const productModelCollection = (products: EventData[] = []) => {
  const productsList = Array.isArray(products) ? products : [products]

  return productsList.map((product, index) => ({
    ...productModel({ ...product, position: product.position || index + 1 }),
  }))
}
