import { H3Error } from 'h3'
import { FetchError as OfetchError } from 'ofetch'

import type {
  ProblemValidation,
  ProblemValidationErrorMember,
} from '../api-models'

const ERROR_STATUS_UNKNOWN = 400

/**
 * Error response version
 *
 * @see {@link https://backmarket.atlassian.net/wiki/spaces/API/pages/2503540766/Badoom+How+to+migrate+to+the+new+error+response [Badoom] How to migrate to the new error response}
 */
enum ErrorResponseVersion {
  UNKNOWN = 'ErrorResponseVersionUnknown',
  V1 = 'ErrorResponseVersionV1',
  V2 = 'ErrorResponseVersionV2',
  V3 = 'ErrorResponseVersionV3',
}

const UNKNOWN_PROBLEM_TYPES = {
  NOT_AN_OFETCH_ERROR: '/errors/unknown/not-an-ofetch-error',
  NOT_IN_PROBLEM_FORMAT: '/errors/unknown/not-in-problem-format',
  PROBLEM_V1_MISSING_CODE: '/errors/unknown/missing-code-in-v1-problem',
  PROBLEM_V2_MISSING_CODE: '/errors/unknown/missing-code-in-v2-problem',
  PROBLEM_V3_MISSING_TYPE: '/errors/unknown/missing-type-in-v3-problem',
}

// The HttpApiError is currently overriden by H3Error.
// Some work needs to be done in order to provide the payload of HttpApiError
// as data within the H3Error.
export type HttpError = H3Error

/**
 * HttpApiError represents an error that is compatible with the
 * TODO: Migrate this doc to Dev Portal
 * {@link https://backmarket.stoplight.io/explore/api-models/50e0565bc69ae-problem Problem Details for HTTP APIs}.
 *
 * @see {@link https://github.com/BackMarket/api-models/blob/main/models/Problem.yaml Problem OpenAPI specs}
 * @see {@link https://backmarket.atlassian.net/wiki/spaces/API/pages/2503540766/Badoom+How+to+migrate+to+the+new+error+response [Badoom] How to migrate to the new error response}
 */
export class HttpApiError extends Error implements ProblemValidation {
  public readonly type: string

  public readonly title: string

  public readonly status: number

  public readonly errors: ProblemValidationErrorMember[]

  /**
   * Format version of the original error, that caused this error. This is
   * useful to keep, in order to track endpoints that should be migrated to the
   * latest error format.
   *
   * @see {@link https://backmarket.atlassian.net/wiki/spaces/API/pages/2503540766/Badoom+How+to+migrate+to+the+new+error+response [Badoom] How to migrate to the new error response}
   */
  public readonly errorResponseVersion: ErrorResponseVersion

  public readonly detail?: string

  public readonly instance?: string

  constructor(
    cause: Error,
    {
      type,
      title,
      status,
      detail,
      instance,
      errors,
      errorResponseVersion,
      ...customData
    }: ProblemValidation & {
      errorResponseVersion: ErrorResponseVersion
    } & Record<string, unknown>,
  ) {
    // Read: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/cause
    // Read: https://caniuse.com/mdn-javascript_builtins_error_cause
    super(title, { cause })
    this.type = type
    this.title = title
    this.status = status
    this.detail = detail
    this.instance = instance
    this.errors = errors || []
    this.errorResponseVersion = errorResponseVersion

    Object.assign(this, customData)
  }

  /**
   * Formats any Error instance to HttpApiError
   *
   * @param error Any error, but typically an {@link OfetchError} instance.
   * @returns An instance of HttpApiError
   */
  public static fromAnyError(error: Error) {
    if (error instanceof HttpApiError) {
      return error
    }

    if (error instanceof OfetchError) {
      return this.fromOfetchError(error)
    }

    // For some reason Nuxt wraps `HttpApiError` in an H3Error on `useHttpFetch`usage
    // In those case we loose the `HttpApiError` data but the original OfetchError is still available as `cause`
    if (error instanceof H3Error && error.cause instanceof OfetchError) {
      return this.fromOfetchError(error.cause)
    }

    return this.fromVanillaError(error)
  }

  private static fromVanillaError(error: Error) {
    return new this(error, {
      errorResponseVersion: ErrorResponseVersion.UNKNOWN,
      errors: [],
      status: 400,
      title: error.message,
      type: UNKNOWN_PROBLEM_TYPES.NOT_AN_OFETCH_ERROR,
    })
  }

  private static fromOfetchError(error: OfetchError) {
    if (error.data?.title) {
      // Response format is ErrorResponseVersion.V3
      // https://github.com/BackMarket/api-models/blob/main/models/Problem.yaml
      return this.fromErrorResponseV3(error)
    }

    if (Array.isArray(error.data?.errors)) {
      // Response format is ErrorResponseVersion.V2
      // https://github.com/BackMarket/api-design-guidelines/blob/master/models/API-Model-Error.md
      return this.fromErrorResponseV2(error)
    }

    // Response format is ErrorResponseVersion.V1
    if (error.data?.error) {
      return this.fromErrorResponseV1(error)
    }

    // Response format is not an ErrorResponseVersion
    return this.fromUnknownErrorResponse(error)
  }

  private static fromErrorResponseV3(error: OfetchError<ProblemValidation>) {
    const data = error.data as ProblemValidation

    return new this(error, {
      ...data,
      errorResponseVersion: ErrorResponseVersion.V3,
      errors: data.errors || [],
      status: data.status || error.status || ERROR_STATUS_UNKNOWN,
      title: data.title,
      type: data.type || UNKNOWN_PROBLEM_TYPES.PROBLEM_V3_MISSING_TYPE,
    })
  }

  private static fromErrorResponseV2(error: OfetchError) {
    // We only consider the first errors, in order to harmonize the format
    // (the other ones remain available through the `cause` property)
    const firstError = error.data.errors[0] || {}

    return new this(error, {
      ...firstError,
      errorResponseVersion: ErrorResponseVersion.V2,
      errors: [],
      status: error.status || ERROR_STATUS_UNKNOWN,
      title: firstError.message || 'Unknown error (error response v2)',
      type: firstError.code || UNKNOWN_PROBLEM_TYPES.PROBLEM_V2_MISSING_CODE,
    })
  }

  private static fromErrorResponseV1(error: OfetchError): HttpApiError {
    const { data } = error

    return new this(error, {
      ...data.error,
      errorResponseVersion: ErrorResponseVersion.V1,
      errors: Object.keys(data.data?.fields || {}).map((fieldKey) => ({
        target: fieldKey,
        detail: data.data?.fields[fieldKey].join('\n'),
        code: null,
      })),
      status: data.status || error.status || ERROR_STATUS_UNKNOWN,
      title: Array.isArray(data.data?.global)
        ? data.data.global.join()
        : data.data?.global ||
          data.error?.message ||
          'Unknown error (error response v1)',
      type: data.error?.code || UNKNOWN_PROBLEM_TYPES.PROBLEM_V1_MISSING_CODE,
    })
  }

  private static fromUnknownErrorResponse(error: OfetchError) {
    return new this(error, {
      errorResponseVersion: ErrorResponseVersion.UNKNOWN,
      errors: [],
      status: error.status || ERROR_STATUS_UNKNOWN,
      title: error.message,
      type: UNKNOWN_PROBLEM_TYPES.NOT_IN_PROBLEM_FORMAT,
    })
  }
}
