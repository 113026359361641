<template>
  <RevIllustration
    :alt="i18n(translations.bouyguesIcon)"
    :height="size"
    src="/img/bouygues/bouyguesLogoSquare.svg"
    :width="size"
  />
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './BouyguesLogo.translations'

withDefaults(
  defineProps<{
    size?: number
  }>(),
  {
    size: 24,
  },
)

const i18n = useI18n()
</script>
