import type { GetOfferResponseLegacy } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferLegacy'
import type { GetOfferV1Response } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferV1'
import type { Currency } from '@backmarket/http-api/src/standards/Currency'
import type { I18n } from '@backmarket/nuxt-module-i18n/types'

export function buybackOfferToLegacyOffer(
  offer: GetOfferV1Response,
  queries: Record<string, unknown> | null,
  currencySign: I18n['currencySign'],
): GetOfferResponseLegacy {
  const date = new Date()
  const currency = offer.listing.price.currency as Currency

  return {
    title: offer.product.fullTitle,
    thumbnail: offer.product.image,
    price: Number(offer.listing.price.amount),
    price_with_currency: `${offer.listing.price.amount.replace('.', ',')} ${currencySign(currency)}`,
    standard_price: {
      amount: offer.listing.price.amount,
      currency,
    },
    sourcing_listing: Number(offer.listing.listingId),
    sourcing_listing_public_id: offer.listing.listingPublicId,
    date_created: date.toISOString().slice(0, 19).replace('T', ' '),
    wording: offer.instructions,
    diagnostic_summary: offer.checkup,
    diagnostic_payload: queries ? { ...queries } : {},
    has_shipping_choices: offer.shipping.hasChoice,
    hasBankDetails: offer.bankDetailsRequired,
    defaultShippingModeId: offer.shipping.default,
  }
}

export function legacyOfferToBuybackOffer(
  offer: GetOfferResponseLegacy,
): GetOfferV1Response {
  return {
    product: {
      fullTitle: offer.title,
      image: offer.thumbnail,
      productId: offer.sourcing_listing.toString(),
      shortTitle: offer.title,
    },
    listing: {
      price: {
        amount: offer.price.toString(),
        currency: offer.standard_price.currency,
      },
      listingId: offer.sourcing_listing.toString(),
      listingPublicId: offer.sourcing_listing_public_id,
    },
    instructions: offer.wording,
    checkup: offer.diagnostic_summary,
    shipping: {
      hasChoice: offer.has_shipping_choices,
      default: offer.defaultShippingModeId,
    },
    bankDetailsRequired: offer.hasBankDetails,
  }
}
