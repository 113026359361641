import { usePinia } from '#imports'

import { defineStore as _defineStore, getActivePinia } from 'pinia'

/**
 * Safely define a store to avoid using it on the wrong pinia instance.
 * This is done to prevent using the store from another request on the server.
 * For more info, see: https://github.com/BackMarket/front-apps/pull/6740
 */
const safelyDefineStore = ((...args: Parameters<typeof _defineStore>) => {
  const store = _defineStore(...args)

  return (...storeArgs: Parameters<typeof store>) => {
    try {
      if (process.server && usePinia() !== getActivePinia()) {
        // eslint-disable-next-line no-console
        console.error(
          // nosemgrep: javascript.lang.security.audit.unsafe-formatstring.unsafe-formatstring
          `[pinia] usePinia() !== getActivePinia() for the store ${args[0]}`,
        )
      }
    } catch (error) {
      // when misused, `usePinia` throws an error
      // [nuxt] A composable that requires access to the Nuxt instance was called outside of a plugin, Nuxt hook, Nuxt middleware, or Vue setup function
      // We will eventually let this error bubble up, but for now we just log it
      // eslint-disable-next-line no-console
      console.error(
        // nosemgrep: javascript.lang.security.audit.unsafe-formatstring.unsafe-formatstring
        `[pinia] the store ${args[0]} was probably used after an await on the server-side`,
        { error: error as Error },
      )
    }

    return store(...storeArgs)
  }
}) as typeof _defineStore

export { safelyDefineStore }
